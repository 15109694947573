import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent, FtmMainComponent} from './app.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {
    AppConfigsService,
    FtAuthModule,
    FtCoreModule,
    ftTranslateFactory,
    FtWsConnectionService,
    FtWsModule,
    JwtConfig,
    POST_AUTH_CONFIG,
    WS_CONFIG
} from '@ft/core';
import {lang} from './i18n';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {JwtModule} from '@auth0/angular-jwt';
import {FtmProductsModule} from './products/products.module';
import {SshModule} from './ssh/ssh.module';
import {FileManagerModule} from './file-manager/file-manager.module';
import {ProductsService} from './products/services/products.service';

import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import { PrintingModule } from './printing/printing.module';

const translateConfig = {
    loader: {
        provide: TranslateLoader,
        useFactory: ftTranslateFactory(lang)
    }
};

export function postAuthFactory(productsService: ProductsService) {
    return [
        productsService.getProducts()
    ];
}


@NgModule({
    declarations: [
        AppComponent,
        FtmMainComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        JwtModule.forRoot(JwtConfig),
        TranslateModule.forRoot(translateConfig),
        FtWsModule.forRoot({
            provide: WS_CONFIG, useFactory: () => new Object({path: 'ws'})
        }),
        FtCoreModule,
        FtAuthModule.forRoot(
            {
                provide: POST_AUTH_CONFIG, deps: [ProductsService], useFactory: postAuthFactory
            }
        ),

        FtmProductsModule,
        FileManagerModule,
        SshModule,

        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),

        PrintingModule,
    ],
    providers: [
        {
            multi: true,
            provide: APP_INITIALIZER,
            deps: [AppConfigsService],
            useFactory: (config: AppConfigsService) => () => config.load('/api/system/'),
        },
        {
            multi: true,
            provide: APP_INITIALIZER,
            deps: [FtWsConnectionService],
            useFactory: (config: FtWsConnectionService) => () => config.connect().toPromise(),
        }
    ],
    bootstrap: [AppComponent]
})
export class FtmAppModule {
}
