import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FormsModule} from '@angular/forms';
import {NgBusyModule} from 'ng-busy';
import {TranslateModule} from '@ngx-translate/core';
import {FtCoreModule, MaterialModule} from '@ft/core';
import {PrintingRoutingModule} from './printing-routing.module';
import {PrintersComponent} from './components/printers/printers.component';
import {PrintersService} from './services/printers.service';
import {PrinterProfileDialog} from './dialogs/printer-profile/printer-profile.dialog';


@NgModule({
    declarations: [
        PrintersComponent, PrinterProfileDialog
    ],
    imports: [
        CommonModule,
        PrintingRoutingModule,
        FormsModule,
        NgBusyModule,
        TranslateModule,
        FtCoreModule,
        MaterialModule
    ],
    providers: [
        PrintersService
    ]
})
export class PrintingModule {
}
