import {
    wsLang as ws,
    authLang as auth,
    commonLang as common,
    coreLang as core
} from '@ft/core';

import {lang as products} from './products/i18n';
import {lang as file_manager} from './file-manager/i18n';
import {lang as printing} from './printing/i18n';
import {lang as ssh} from './ssh/i18n';

export let lang = Object.assign(
    {},
    {ws},
    {auth},
    {core},
    {common},
    {products},
    {file_manager},
    {ssh},
    {printing},
    {
        shared: {
            copyright: '',
            cups_title: 'CUPS',
            no_sql_client_title: 'NoSqlClient',
            machine_code_copy_success: 'Code machine a été copié avec succès',

            restart: {
                tooltip: 'Redémarrage planifié',
                title: 'Paramètres de redémarrage',
                cron_config: 'Paramètrage du CRON',
                config_success: 'Paramètres de redémarrage modifiés avec succès'
            }
        }
    }
);
