import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard, BaseLoginComponent, LoggedGuard} from '@ft/core';

import {FtmMainComponent} from './app.component';

const routes: Routes = [
    {
        path: '', pathMatch: 'full', redirectTo: '/products',
    },
    {
        path: 'login',
        canActivate: [LoggedGuard],
        component: BaseLoginComponent,
        data: {logo: '/assets/logo.png'}
    },
    {
        path: '',
        canActivate: [AuthGuard],
        component: FtmMainComponent,
        children: [
            {
                path: 'products',
                loadChildren: () => import('./products/products-routing.module').then(m => m.ProductsRoutingModule)
            },
            {
                path: 'file-manager/main',
                loadChildren: () => import('./file-manager/file-manager-routing.module').then(m => m.FileManagerRoutingModule)
            },
            {
                path: 'printers',
                loadChildren: () => import('./printing/printing-routing.module').then(m => m.PrintingRoutingModule)
            }
        ]
    },
    {
        path: 'ssh',
        loadChildren: () => import('./ssh/ssh-routing.module').then(m => m.SshRoutingModule)
    },
    {
        path: '**', pathMatch: 'full', redirectTo: '/products'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
