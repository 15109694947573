export const lang = {
    title: 'Produits',
    actions: 'Actions',
    version: 'Version',
    added_at: 'Ajouter le',
    packaged_at: 'Package du',
    is_installed: 'Installé',
    is_installable: 'Installable',
    require_licence: 'Nécessite une licence',
    required_versions_rep: 'Versions requises',

    installed_versions: 'installées',
    previous_versions: 'précédentes',

    execute_install: 'Installer',
    update_licence_key: 'Modifier la clé de licence',

    comment: 'Commentaire',
    remove_package: 'Voulez vous supprimé le package "{{type}} - {{version}}"',

    handle_product: 'Produit: "{{name}}"',
    serve_ip: 'IP de produit',
    serve_dns: 'Dns du produit',

    product: {
        licence_key: 'Clé de licence',
        licence_key_invalid: 'Clé fournie non valide',
        licence_machine_uuid_invalid: 'La clé fourni n\'est pas n\'est pas destinée à cette machine',
        licence_product_invalid: 'Le produit du clé fourni n\'est pas n\'est pas destinée à ce produit',
        licence_package_version_invalid: 'La version de clé fournie n\'est pas destinée à cette version de package',
    },

    service: {
        Id: 'Id',
        User: 'User',
        Group: 'Group',
        LoadState: 'LoadState',
        ActiveState: 'ActiveState',
        SubState: 'SubState',
        ExecMainPID: 'ExecMainPID',
        FragmentPath: 'FragmentPath',
        UnitFileState: 'UnitFileState',

        stop: 'Arrêter',
        enable: 'Activer',
        disable: 'Désactiver',
        start_restart: 'Démarrer / Redémarrage',
    },

    certificate: {
        ip_list: 'Liste des IP',
        dns_list: 'Liste des DNS',
        ip_item: 'IP: {{index}}',
        dns_item: 'DNS: {{index}}',
        dialog_title: 'Certificat SSL',
        common_name: 'Common Name',
        add_ip: 'IP',
        add_dns: 'DNS',
        download_keystore: 'Télécharger keystore',
        lets_encrypt: 'Let\'s Encrypt'
    },

    backup: {
        label: 'Backups',
        backups: 'Copies N° {{index}}',
        cron: 'Cron config N° {{index}}',
        destination: 'Destination N° {{index}}',
        add_backup: 'Ajouter backup',
        main_args: 'Arguments du script principaux'
    }
};
